import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

const PSWP_TEMPLATE_SELECTOR = '.pswp.pswp-footer'

/**
 *
 * WARNING! Этот файл не нужно редактировать!
 *
 * универсальная функция инициализации слайдов
 * Требуется чтобы шаблон .pswp.pswp-footer был подключен в DOM (существовал)
 *
 * Функция взята из оф. документации PhotoSwipe.
 *
 * @see https://photoswipe.com/documentation/getting-started.html
 *
 * Критически важно, чтобы разметка соответствовала требованиям
 *
 * ```html
 * <div class="my-gallery" itemscope itemtype="http://schema.org/ImageGallery">
 *
 *  <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
 *       <a href="large-image.jpg" itemprop="contentUrl" data-size="600x400">
 *            <img src="small-image.jpg" itemprop="thumbnail" alt="Image description" />
 *        </a>
 *        <figcaption itemprop="caption description">
 *          Image caption
 *        </figcaption>
 *  </figure>
 *
 *    ...
 *
 * </div>
 * ```
 *
 * НЕ используйте `.my-gallery` !!! Это просто пример, называйте
 * галереи и элементы правильными логичными именами.
 *
 *
 * Работает только с одним уровнем вложенности и
 * строгой структуре элементов.
 *
 */
const initPhotoSwipeFromDOM = function (gallerySelector) {
  // parse slide data (url, title, size ...) from DOM elements
  // (children of gallerySelector)
  const parseThumbnailElements = function (el) {
    let thumbElements = el.childNodes,
      numNodes = thumbElements.length,
      items = [],
      figureEl,
      linkEl,
      size,
      item;

    for (var i = 0; i < numNodes; i++) {
      figureEl = thumbElements[i]; // <figure> element
      // include only element nodes
      if (figureEl.nodeType !== 1) {
        continue;
      }

      linkEl = figureEl.children[0]; // <a> element
      size = linkEl.getAttribute('data-size').split('x');
      // create slide object
      item = {
        src: linkEl.getAttribute('href'),
        w: parseInt(size[0], 10),
        h: parseInt(size[1], 10)
      };
      if (figureEl.children.length > 1) {
        // <figcaption> content
        item.title = figureEl.children[1].innerHTML;
      }

      if (linkEl.children.length > 0) {
        // <img> thumbnail element, retrieving thumbnail url
        item.msrc = linkEl.children[0].getAttribute('src');
      }

      item.el = figureEl; // save link to element for getThumbBoundsFn
      items.push(item);
    }

    return items;
  };

  // find nearest parent element
  const closest = function closest(el, fn) {
    return el && (fn(el) ? el : closest(el.parentNode, fn));
  };

  // triggers when user clicks on thumbnail
  const onThumbnailsClick = function (e) {
    e = e || window.event;
    e.preventDefault ? e.preventDefault() : e.returnValue = false;

    let eTarget = e.target || e.srcElement;

    // find root element of slide
    let clickedListItem = closest(eTarget, function (el) {
      return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
    });

    if (!clickedListItem) {
      return;
    }

    // find index of clicked item by looping through all child nodes
    // alternatively, you may define index via data- attribute
    let clickedGallery = clickedListItem.parentNode,
      childNodes = clickedListItem.parentNode.childNodes,
      numChildNodes = childNodes.length,
      nodeIndex = 0,
      index;

    for (let i = 0; i < numChildNodes; i++) {
      if (childNodes[i].nodeType !== 1) {
        continue;
      }

      if (childNodes[i] === clickedListItem) {
        index = nodeIndex;
        break;
      }
      nodeIndex++;
    }


    if (index >= 0) {
      // open PhotoSwipe if valid index found
      openPhotoSwipe(index, clickedGallery);
    }
    return false;
  };

  // parse picture index and gallery index from URL (#&pid=1&gid=2)
  const photoswipeParseHash = function () {
    let hash = window.location.hash.substring(1),
      params = {};

    if (hash.length < 5) {
      return params;
    }

    let vars = hash.split('&');
    for (let i = 0; i < vars.length; i++) {
      if (!vars[i]) {
        continue;
      }
      let pair = vars[i].split('=');
      if (pair.length < 2) {
        continue;
      }
      params[pair[0]] = pair[1];
    }

    if (params.gid) {
      params.gid = parseInt(params.gid, 10);
    }

    return params;
  };

  const openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
    let pswpElement = document.querySelectorAll(PSWP_TEMPLATE_SELECTOR)[0],
      gallery,
      options,
      items;

    items = parseThumbnailElements(galleryElement);

    // define options (if needed)
    options = {

      // define gallery index (for URL)
      galleryUID: galleryElement.getAttribute('data-pswp-uid'),

      getThumbBoundsFn: function (index) {
        // See Options -> getThumbBoundsFn section of documentation for more info
        let thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
          pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
          rect = thumbnail.getBoundingClientRect();

        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
      }

    };

    // PhotoSwipe opened from URL
    if (fromURL) {
      if (options.galleryPIDs) {
        // parse real index when custom PIDs are used
        // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
        for (let j = 0; j < items.length; j++) {
          if (items[j].pid == index) {
            options.index = j;
            break;
          }
        }
      }
      else {
        // in URL indexes start from 1
        options.index = parseInt(index, 10) - 1;
      }
    }
    else {
      options.index = parseInt(index, 10);
    }

    // exit if index not found
    if (isNaN(options.index)) {
      return;
    }

    if (disableAnimation) {
      options.showAnimationDuration = 0;
    }

    // Pass data to PhotoSwipe and initialize it
    gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
  };

  // loop through all gallery elements and bind events
  const galleryElements = document.querySelectorAll(gallerySelector);

  for (let i = 0, l = galleryElements.length; i < l; i++) {
    galleryElements[i].setAttribute('data-pswp-uid', i + 1);
    galleryElements[i].onclick = onThumbnailsClick;
  }

  // Parse URL and open gallery if it contains #&pid=3&gid=1
  const hashData = photoswipeParseHash();
  if (hashData.pid && hashData.gid) {
    openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
  }
}

/**
 * Пример инициализации для Swiper
 *
 * ```html
 * <figure class="swiper-slide"
 *         style="background-image:url('/path/to/slide/image.jpg')"
 *         itemscope
 *         itemtype="http://schema.org/ImageObject">
 *     <a class="image"
 *        itemprop="contentUrl"
 *        data-size="640x480"
 *        href="/path/to/large/image.jpg">
 *     </a>
 * </figure>
 * ```
 *
 * @param selector {String}
 * @param options {Object}
 */
function initPhotoSwipeFromSwiper(selector, options) {
  const items = []
  const pswp = document.querySelector(PSWP_TEMPLATE_SELECTOR)
  const root = document.querySelector(selector)

  options = options || {}

  if (!pswp) {
    console.warn(`
      pswp: не обнаружен шаблон галереи ${PSWP_TEMPLATE_SELECTOR}.
    `)
    return
  }

  if (!root) {
    console.warn(`
      pswp: выбранный элемент не найден ${selector}
      возможно необходимо вы указали селектор неверно.
      Или требуется проверять наличие селектора перед
      инициализацией галерей.
    `)
    return
  }

  const slides = root.querySelectorAll('.swiper-slide a') // <--

  if (!slides || slides.length === 0) {
    console.warn(`
      pswp: не найдено элементов для инициализации галереи,
      возможно вы используете неправильный селектор:
      ${selector} .swiper-slide a
    `)
    return
  }

  slides.forEach((slide, idx) => {
    let href = slide.getAttribute('href')

    if (!href) {
      console.warn('pswp: у элемента нет атрибута href')
      return
    }

    let sizeAttr = slide.dataset.size

    if (/^\s+x\d+$/.test(sizeAttr)) {
      console.warn(`
        pswp: для инициализации каждого элемента
        должен быть атрибут data-size="WxH", где
        W и H - реальные ширина и высота изображения
        в пикселах.
      `)
      return
    }

    let sizes = sizeAttr.split('x')
    let width = sizes[0]
    let height = sizes[1]

    if (width <= 0 || height <= 0) {
      console.warn(`
        pswp: ширина и высота изображения должны быть
        положительным целым числом больше нуля.
      `)
      return
    }

    items.push({
      src: href,
      w: width,
      h: height
    })

    slide.addEventListener('click', function(e){
      e.preventDefault()
      options.index = idx
      let gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, items, options)
      gallery.init()

      return false
    })
  })
}

/**
 * Индексирует все элементы на странице с заданным `selector`
 * Рекомендуется использовать логичный, семантический селектор, например
 * a[rel=gallery] или a.gallery-item. Но следует помнить, что селектор
 * должен быть уникальным. При этом все элементы попадут в одну общую
 * галерею.
 *
 * разметка (пример)
 *
 * ```html
 * <figure class="article-image" itemscope itemtype="http://schema.org/ImageObject">
 *     <a class="image"
 *        itemprop="contentUrl"
 *        data-size="640x480"
 *        href="/path/to/large/image.jpg">
 *        <img itemprop="thumbnail" src="/path/to/small/image.jpg" alt="Image Alt">
 *     </a>
 *     <figcaption itemprop="caption description">
 *         Full Name or Description
 *     </figcaption>
 * </figure>
 * ```
 *
 * Можно комбинировать для поиска элементов в разных секциях.
 *
 * ```js
 * initPhotoSwipeLinks('.gallery a.image')
 * ```
 *
 * или создать отдельные галереи для каждой секции/родителя
 *
 * ```js
 * document.querySelectorAll('.gallery').forEach((el, idx) => {
 *  const cls = `gallery-${idx}`
 *  el.classList.add(cls)
 *  initPhotoSwipeLinks('.gallery.${cls} a.image')
 * })
 *
 * ```
 *
 * @param selector
 * @param options
 *
 * @see https://developer.mozilla.org/ru/docs/Web/HTML/Element/figure
 * @see https://developer.mozilla.org/ru/docs/Web/HTML/Element/figcaption
 */
function initPhotoSwipeLinks(selector, options) {
  const items = []
  const pswp = document.querySelector(PSWP_TEMPLATE_SELECTOR)
  const slides = document.querySelectorAll(selector)

  options = options || {}

  if (!pswp) {
    console.warn(`
      pswp: не обнаружен шаблон галереи ${PSWP_TEMPLATE_SELECTOR}.
    `)
    return
  }

  if (!slides || slides.length === 0) {
    console.warn(`
      pswp: не найдено элементов для инициализации галереи,
      возможно вы используете неправильный селектор:
      ${selector}
    `)
    return
  }

  slides.forEach((slide, idx) => {
    let href = slide.getAttribute('href')

    if (!href) {
      console.warn('pswp: у элемента нет атрибута href')
      return
    }

    let sizeAttr = slide.dataset.size

    if (/^\s+x\d+$/.test(sizeAttr)) {
      console.warn(`
        pswp: для инициализации каждого элемента
        должен быть атрибут data-size="WxH", где
        W и H - реальные ширина и высота изображения
        в пикселах.
      `)
      return
    }

    let sizes = sizeAttr.split('x')
    let width = sizes[0]
    let height = sizes[1]

    if (width <= 0 || height <= 0) {
      console.warn(`
        pswp: ширина и высота изображения должны быть
        положительным целым числом больше нуля.
      `)
      return
    }

    items.push({
      src: href,
      w: width,
      h: height
    })

    slide.addEventListener('click', function(e){
      e.preventDefault()
      options.index = idx
      let gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, items, options)
      gallery.init()

      return false
    })
  })
}

export {
  initPhotoSwipeFromDOM,
  initPhotoSwipeFromSwiper,
  initPhotoSwipeLinks
}
