



































































































import Vue from 'vue'
import FormCommonMixin from '@/mixins/form-common-mixin'

export default Vue.extend({
  mixins: [FormCommonMixin],
  data() {
    return {
      formId: 4,
    }
  }
})
