/**
 * функциональность сайта
 */
import { mountForm } from '@/site/components/order'
import {
  mountForm as mountFeedbackForm
} from '@/site/components/feedback'
import {
  mountForm as mountDeliveryForm
} from '@/site/components/delivery'
console.log('site')

export const Site = {
  orderForm: mountForm('#order-form'),
  feedbackForm: mountFeedbackForm('#site-feedback-form'),
  deliveryForm: mountDeliveryForm('#delivery-form'),
}
