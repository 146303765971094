import { start as startBackgroundImages } from './background-images'
import { start as startScrollUp } from './scrollup'
import navbar from './navbar'
import pswp from './pswp'
/**
 * все runtime скрипты ожидают загрузки страницы
 */
document.addEventListener('DOMContentLoaded', () => {
  navbar.start() // это первым поставь
  startBackgroundImages()
  startScrollUp('scroll-up-plawa')
  pswp.start()
})
