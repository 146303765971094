<template>
  <div class="platform-feedback-form">
    <validation-observer v-slot="{invalid, handleSubmit}" ref="observer">
      <div class="form-header" ref="header" v-if="isNotSuccess">
        <p class="title is-4" ref="title"> Обратная связь</p>
      </div>

      <div class="form-messages" ref="messages">
        <b-notification type="is-danger" @close="onCloseNotification" has-icon="" v-model="status.error">
          <p>Ошибка! Не удалось отправить сообщение.</p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>
          <template v-if="showExtendedError">
            <p>Описание ошибки:</p>
            <span v-for="(item, index) in errors" :key="index">
              <strong>{{index}}: {{item}}</strong>
            </span>
          </template>
        </b-notification>
      </div>

      <b-notification type="is-success" @close="onCloseNotification" has-icon="" v-model="status.success">
        <p>Спасибо! Ваше сообщение отправлено.</p>
        <p>Постараемся ответить как можно быстрее.</p>
      </b-notification>

      <div class="form-wrapper" v-if="isNotSuccess">
        <form @submit.prevent.stop="handleSubmit(submit)" class="form" ref="form" enctype="multipart/form-data">
          <input type="hidden" name="WEB_FORM_ID" :value="formId">

          <validation-provider v-slot="v" rules="required" name="Контакты" slim>
            <b-field label="Контакты *" message="Ваши контакты, Ф.И.О" :type="{ 'is-danger': v.invalid && v.dirty }">
              <b-input name="form_text_41" placeholder="Имя и Фамилия" v-model="formData.name"></b-input>
            </b-field>
            <p class="has-text-danger">{{v.errors[0]}}</p>
          </validation-provider>

          <validation-provider v-slot="v" rules="required" name="Телефон" slim>
            <b-field label="Телефон *" message="Ваш контактный телефон">
              <b-input name="form_text_38" placeholder="+7 000 000-00-00" v-model="formData.phone"></b-input>
            </b-field>
            <p class="has-text-danger">{{v.errors[0]}}</p>
          </validation-provider>

          <validation-provider v-slot="v" rules="required|email" name="Email" slim>
            <b-field label="Email *" message="Ваш адрес электронной почты" :type="{ 'is-danger': v.invalid && v.dirty }">
              <b-input name="form_email_2" placeholder="email@email.ru" v-model="formData.email"></b-input>
            </b-field>
            <p class="has-text-danger">{{v.errors[0]}}</p>
          </validation-provider>

          <validation-provider v-slot="v" rules="required|max:1000" slim name="Сообщение">
            <b-field label="Сообщение *" message="Сообщение или ваши вопросы" type="{'is-danger': v.invalid}">
              <b-input name="form_textarea_1" placeholder="сообщение или вопрос..." type="textarea" v-model="formData.message"></b-input>
            </b-field>
            <p class="has-text-danger">{{v.errors[0]}}</p>
          </validation-provider>

          <b-field class="file is-primary" :class="{'has-name': !!formData.file}">
            <b-upload class="file-label" v-model="formData.file" name="form_file_51">
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label"> прикрепить файл</span>
            </span>
              <span class="file-name" v-if="formData.file">{{ formData.file.name }}</span>
            </b-upload>
          </b-field>

          <b-message style="margin-top: 2rem;">
            <p class="help">
              Отправляя сообщение вы соглашаетесь <a target="_blank" href="/agreement">с условиями</a>. Если вы не
              согласны <a target="_blank" href="/agreement">с условиями
              пользовательского соглашения</a>, не отправляйте форму.
              Ваши данные будут использованы только для связи с вами, для ответа на ваше сообщение.
            </p>
          </b-message>

          <div class="controls">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <b-button native-type="reset"> очистить форму</b-button>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <b-button type="is-primary" native-type="submit" :disabled="invalid" :class="{'is-loading': isLock}">отправить</b-button>
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue'
import FormCommonMixin from '@/mixins/form-common-mixin'

export default Vue.extend({
  mixins: [FormCommonMixin],
  data() {
    return {
      formId: 1,
    }
  }
})
</script>

<style lang="sass" scoped>
@import 'helpers'
// common
.platform-feedback-form
  background: #fff
.form-header
  margin-bottom: 1rem
  padding-bottom: 1rem
  border-bottom: 1px solid #eee
  background: #fff
// in modal
.form-in-modal .platform-feedback-form
  .form-wrapper
    background: #fff
    padding: 2rem
  .form-header
    background: $primary
    p.title
      padding-left: 2rem
      padding-top: 1rem
      color: $primary-invert
</style>
