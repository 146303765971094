import Vue from 'vue'
import FeedbackForm from "@/site/components/feedback/FeedbackForm.vue"
import FeedbackFormTrigger from "@/site/components/feedback/FeedbackFormTrigger.vue"

/**
 * Подключение формы на страницу. По возможности
 * используйте для `selector` идентификатор, чтобы гарантировать
 * уникальность формы на странице. Если нужно много форм на странице,
 * лучше использовать триггеры.
 */
export function mountForm(selector: string): Vue|boolean {
  if (!document.querySelector(selector)) return false
  return new Vue({
    render: h => h(FeedbackForm)
  }).$mount(selector)
}

/**
 * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
 * кнопка для открытия формы. Для подключения обычно используется пустой
 * HTML-элемент (div, span).
 *
 * ```html
 * <div class="js-callback-form-trigger">
 *   <feedback-form-trigger title="Feedback"></feedback-form-trigger>
 * </div>
 * ```
 *
 * По-умолчанию class - `js-callback-form-trigger`. То есть если
 * запустить без параметров триггер примонтируется на элементы с
 * классом `js-callback-form-trigger`.
 *
 * Всегда используйте css-классы, не используйте `id`.
 *
 */
export function mountTrigger(selector = '.js-feedback-form-trigger'): void {
  const elements: NodeListOf<HTMLElement> = document.querySelectorAll(selector)
  elements.forEach(el => new Vue({ components: { FeedbackFormTrigger } }).$mount(el))
}

/**
 * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
 * кнопка для открытия формы. Для подключения обычно используется пустой
 * HTML-элемент (div, span).
 *
 * ```html
 * <div class="js-callback-form-trigger"></div>
 * ```
 *
 * По-умолчанию class - `js-callback-form-trigger`. То есть если
 * запустить без параметров триггер примонтируется на элементы с
 * классом `js-callback-form-trigger`.
 *
 * Всегда используйте css-классы, не используйте `id`.
 *
 */
export function renderTrigger(selector = '.js-callback-form-trigger'): void {
  const elements: NodeListOf<HTMLElement> = document.querySelectorAll(selector)
  elements.forEach(el => new Vue({ render: h => h(FeedbackFormTrigger)}).$mount(el))
}


export {
  FeedbackForm,
  FeedbackFormTrigger,
}
