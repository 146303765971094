import Vue from "vue"
import {ValidationObserver, ValidationProvider, extend, localize, configure} from "vee-validate"
import {required, email, max, length, min, min_value, numeric} from "vee-validate/dist/rules"
import ru from 'vee-validate/dist/locale/ru.json'

/**
 * @see https://logaretm.github.io/vee-validate/
 * @see https://logaretm.github.io/vee-validate/guide/state.html#flags
 *
 * Для сброса состояния `ValidationObserver` имеет метод `reset`.
 *
 * для того чтобы сбросить состояние формы используется к начальному
 * можно использовать ref на объекте ValidationObserver(ref="form")
 *
 * this.$refs.form.reset();
 *
 * Чтобы программно запустить проверку полей
 *
 * this.$refs.form.validate().then(success => {
 *   if (!success) {
 *     return
 *   }
 *
 *   ...
 *
 *   this.$nextTick(() => {
 *     this.$refs.form.reset()
 *   })
 * })
 *
 *
 */

localize('ru', ru)

configure({
  classes: {
    valid: 'is-success',
    invalid: 'is-danger',
    //pristine: 'is-pristine',
    // dirty: ['is-dirty']
  }
})

/**
 * register validation components globally
 */
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

extend('required', required)
extend('email', email)
extend('length', length)
extend('max', max)
extend('min', min)
extend('min_value', min_value)
extend('numeric', numeric)

// custom rules

/**
 * общая сумма не может быть меньше 3000
 */
extend('total', value => {
  if (value > 3000) {
    return true
  }

  return 'Минимальная сумма заказа 3000 рублей'
})

/**
 * площадь в диапазоне 10 < 100
 */
extend('area', value => {
  if(value >= 10 && value < 100) {
    return true
  }

  return `Общая площадь потолка должна быть 10 - 100 кв.м.
    Для площади 100 и более кв.м. требуется индивидуальный расчет`
})

export {
  ValidationObserver,
  ValidationProvider,
}
